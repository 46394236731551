<template>
  <div id="app">
    <b-navbar toggleable="lg" type="light">
      <b-navbar-brand href="#" @click="euChange"
        ><b-img
          class="logo"
          :src="env.VUE_APP_API + 'img/faldonew.jpeg'"
        ></b-img
      ></b-navbar-brand>

      <b-navbar-nav class="mobShow">
        <b-nav-item
          class="menuS"
          :href="'https://www.facebook.com/' + env.VUE_APP_FACEBOOK_NAME"
          target="_blank"
          rel="noopener"
        >
          <font-awesome-icon id="fb" class="icon" :icon="['fab', 'facebook']" />
        </b-nav-item>
        <b-nav-item
          class="menuS"
          :href="'https://twitter.com/' + env.VUE_APP_TWITTER_NAME + '?lang=en'"
          target="_blank"
          rel="noopener"
        >
          <font-awesome-icon id="fb" class="icon" :icon="['fab', 'twitter']" />
        </b-nav-item>
        <b-nav-item
          class="menuS"
          :href="
            'https://www.instagram.com/' + env.VUE_APP_INSTA_NAME + '/?hl=en'
          "
          target="_blank"
          rel="noopener"
        >
          <font-awesome-icon
            id="fb"
            class="icon"
            :icon="['fab', 'instagram']"
          />
        </b-nav-item>
        <b-nav-item
          class="menuS"
          :href="'https://www.youtube.com/user/' + env.VUE_APP_YOUTUBE_NAME"
          target="_blank"
          rel="noopener"
        >
          <font-awesome-icon id="fb" class="icon" :icon="['fab', 'youtube']" />
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item @click="euChange" to="/" :class="{red:active === true}" class="link"
            >Faldo Series Europe</b-nav-item
          >
          <b-nav-item @click="asiaChange" to="/" :class="{red:active === false}" class="link"
            >Faldo Series Asia</b-nav-item
          >
          <b-nav-item
            href="https://faldohurricane.com/"
            target="_blank"
            class="link"
            >Faldo Series USA</b-nav-item
          >
          <template v-if="log.status === 'OK'">
            <b-dropdown
              id="dropdown-1"
              :text="'Welcome ' + log.playerName"
              class="m-md-2 player"
            >
              <b-dropdown-item @click="signOut">Sign Out</b-dropdown-item>
              <b-dropdown-item>Change Password</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto mobHide">
          <b-nav-item
            :to="{
              name: 'privacy',
              query: { wppage: '103' },
            }"
            class="link hideSmall"
            >T&Cs and Privacy</b-nav-item
          >
          <b-nav-item
            href="https://www.nickfaldo.com/"
            target="_blank"
            class="hideSmall"
            >NickFaldo.com</b-nav-item
          >
          <b-nav-item
            class="menuS"
            :href="'https://www.facebook.com/' + env.VUE_APP_FACEBOOK_NAME"
            target="_blank"
            rel="noopener"
          >
            <font-awesome-icon
              id="fb"
              class="icon"
              :icon="['fab', 'facebook']"
            />
          </b-nav-item>
          <b-nav-item
            class="menuS"
            :href="
              'https://twitter.com/' + env.VUE_APP_TWITTER_NAME + '?lang=en'
            "
            target="_blank"
            rel="noopener"
          >
            <font-awesome-icon
              id="fb"
              class="icon"
              :icon="['fab', 'twitter']"
            />
          </b-nav-item>
          <b-nav-item
            class="menuS"
            :href="
              'https://www.instagram.com/' + env.VUE_APP_INSTA_NAME + '/?hl=en'
            "
            target="_blank"
            rel="noopener"
          >
            <font-awesome-icon
              id="fb"
              class="icon"
              :icon="['fab', 'instagram']"
            />
          </b-nav-item>
          <b-nav-item
            class="menuS"
            :href="'https://www.youtube.com/user/' + env.VUE_APP_YOUTUBE_NAME"
            target="_blank"
            rel="noopener"
          >
            <font-awesome-icon
              id="fb"
              class="icon"
              :icon="['fab', 'youtube']"
            />
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view
      :euAsia="euAsia"
      :tours="tours"
      :key="$route.fullPath"
      :ct="ct"
      :season="season"
      :config="env"
      :log="log"
    />
    <div class="footer">
      <b-row class="rowFooter">
        <b-col class="rowFooters">
          <b-navbar class="footNav" type="dark">
            <b-navbar-nav>
              <b-nav-item
                :to="{
                  name: 'privacy',
                  query: { wppage: '103' },
                }"
                class="link"
                >T&Cs and Privacy</b-nav-item
              >
              <b-nav-item
                href="https://www.nickfaldo.com/"
                target="_blank"
                class="link"
                >NickFaldo.com</b-nav-item
              >
              <b-nav-item
                :to="{
                  name: 'contact',
                  query: { wppage: '114' },
                }"
                >Contact Us</b-nav-item
              >
              <b-nav-item @click="euChange" to="/" class="link"
                >Flado Series Europe</b-nav-item
              >
              <b-nav-item @click="asiaChange" to="/" class="link"
                >Flado Series Asia</b-nav-item
              >
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item
                class="menuS"
                :href="'https://www.facebook.com/' + env.VUE_APP_FACEBOOK_NAME"
                target="_blank"
                rel="noopener"
              >
                <font-awesome-icon
                  id="fb"
                  class="icon"
                  :icon="['fab', 'facebook']"
                />
              </b-nav-item>
              <b-nav-item
                class="menuS"
                :href="
                  'https://twitter.com/' + env.VUE_APP_TWITTER_NAME + '?lang=en'
                "
                target="_blank"
                rel="noopener"
              >
                <font-awesome-icon
                  id="fb"
                  class="icon"
                  :icon="['fab', 'twitter']"
                />
              </b-nav-item>
              <b-nav-item
                class="menuS"
                :href="
                  'https://www.instagram.com/' +
                    env.VUE_APP_INSTA_NAME +
                    '/?hl=en'
                "
                target="_blank"
                rel="noopener"
              >
                <font-awesome-icon
                  id="fb"
                  class="icon"
                  :icon="['fab', 'instagram']"
                />
              </b-nav-item>
              <b-nav-item
                class="menuS"
                :href="
                  'https://www.youtube.com/user/' + env.VUE_APP_YOUTUBE_NAME
                "
                target="_blank"
                rel="noopener"
              >
                <font-awesome-icon
                  id="fb"
                  class="icon"
                  :icon="['fab', 'youtube']"
                />
              </b-nav-item>
            </b-navbar-nav>
          </b-navbar>
        </b-col>
      </b-row>
      <Footer />
      <b-row>
        <b-col lg="3">
          <p>
            The Faldo Trust For Tomorrow's Champions
          </p>
          <p>
            Company Number - 3295676
          </p>
          <p>
            Charity Number - 1102719
          </p>
        </b-col>
        <b-col lg="3">
          <p>
            Registered address :
          </p>
          <p>
            19 Russell Street Windsor Berkshire
            <br />
            SL4 1HQ United Kingdom
          </p>
          <br />
          <p>
            © 2021 Faldo Series
          </p>
        </b-col>
        <b-col lg="3">
          <p>
            Email:
            <br />
            <a href="mailto:fsentries@nickfaldo.com" style="color:white;">
              fsentries@nickfaldo.com
            </a>
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Footer from "@/components/footer.vue";
import { bus } from "./main.js";
export default {
  components: {
    Footer,
  },
  data: function() {
    return {
      euAsia: "",
      tours: [],
      season: "",
      ct: "",
      env: [],
      log: "",
      active:true
    };
  },
  methods: {
    euChange() {
      this.euAsia = "321";
      this.active = true;
      if (this.log === "")
        axios
          .get(
            process.env.VUE_APP_API +
              "inc/xsjs.php?type=xml2json&url=" +
              process.env.VUE_APP_TIC_BASE +
              "tourn=auto~tourns=y~seasons=y~jsout=x~xsl=~?randomadd=" +
              new Date().getTime()
          )
          .then((response) => {
            this.tours = response.data;
            this.season = response.data.tm_params.season_code;
            this.ct = response.data.code;
          });
      else {
        axios
          .get(
            process.env.VUE_APP_API +
              "inc/xsjs.php?type=xml2json&url=" +
              process.env.VUE_APP_TIC_BASE +
              "tourn=auto~player=" +
              this.log.playerID +
              "~tourns=y~seasons=y~jsout=x~xsl=~?randomadd=" +
              new Date().getTime()
          )
          .then((response) => {
            this.tours = response.data;
            this.season = response.data.tm_params.season_code;
            this.ct = response.data.code;
          });
      }
    },
    asiaChange() {
      this.euAsia = "466";
      this.active = false;
      axios
        .get(
          process.env.VUE_APP_API +
            "inc/xsjs.php?type=xml2json&url=" +
            process.env.VUE_APP_TIC_BASE_ASIA +
            "season=" +
            this.season +
            "~tourn=auto~tourns=y~seasons=y~jsout=x~xsl=~?randomadd=" +
            new Date().getTime()
        )
        .then((response) => {
          this.tours = response.data;
          this.ct = response.data.code;
        });
    },
          signOut(){
      sessionStorage.playerName = null;
    	sessionStorage.playerID = null;
    	sessionStorage.password = null;
      sessionStorage.status = null;
      this.log = {playerName:null,playerID:null,status:null};
      bus.$emit("log", { log: this.log })
      }
  },
  created() {
    bus.$on("log", (data) => {
      (this.log = data.log),
        axios
          .get(
            process.env.VUE_APP_API +
              "inc/xsjs.php?type=xml2json&url=" +
              process.env.VUE_APP_TIC_BASE +
              "tourn=auto~player=" +
              data.log.playerID +
              "~tourns=y~seasons=y~jsout=x~xsl=~?randomadd=" +
              new Date().getTime()
          )
          .then((response) => {
            this.tours = response.data;
            this.season = response.data.tm_params.season_code;
            this.ct = response.data.code;
          });
    });
  },
  mounted() {
    this.env = process.env;
    axios.get(this.euChange());
  },
};
</script>

<style>

#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1 !important;
}
.mobShow {
  display: none !important;
}
.navbar {
  background-color: white;
  border-color: #e7e7e7;
  border: 2px solid #e1e1e1;
}
.nav-link {
  display: block;
  padding: 0 15px;
  color: #404040 !important;
  font-weight: 600;
  font-size: 1.125rem;
  font-family: "Open Sans", sans-serif !important;
  transition: padding 0.25s;
  transform: translateZ(0);
}
.logo {
  max-width: 85px;
  text-align: center;
  float: left;
  margin: 0;
  padding-left: 20px;
  margin-top: 10px;
}
.red>a{
  color:#860038 !important;
}
.icon {
  font-size: 24px;
}
.footer {
  background: #860038;
  padding: 50px;
  color: white;
}
.footNav {
  background-color: transparent !important;
  border: 0 !important;
}
.footNav > ul > li > a {
  color: white !important;
  font-size: 14px !important;
}
.player > button {
  color: #004812 !important;
  font-size: 16px;
  text-transform: initial;
  cursor: auto;
  background-color: transparent;
  border: 0;
}
@media only screen and (max-width: 1028px) {
  .hideSmall {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .mobHide {
    display: none !important;
  }
  .mobShow {
    display: inline-flex !important;
    flex-direction: row !important;
    margin-right: 400px;
  }
  .menuS {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .footNav {
    flex-flow: column !important;
  }
  .mobShow {
    margin-right: 50px;
  }
  nav.navbar.footNav.navbar-dark.navbar-expand > ul.navbar-nav {
    display: block;
    float: left;
    padding-left: 0px;
    margin-left: -10px!important;
  }
  .menuS {
    margin-right: 15px;
    display: inline-table;
  }
  .rowFooters.col > .navbar{
    align-items: baseline!important;
  }
}
@media (min-width: 992px){
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 1.5rem!important;
}
}
</style>
