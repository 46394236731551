<template>
  <b-row class="spon">
    <b-col cols="6" lg="1" xs="6" v-for="(spons, index) in sponsor.slice().reverse()" :key='index'>
      <a :href="spons.acf.sponsor_url">
      <b-img
        class="sponLogo"
        :src="spons.acf.image"
      ></b-img>
      </a>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios'
export default {
    name:'footer',
    data(){
        return{
            sponsor:[]
        }
    },
      mounted() {
    axios.get(process.env.VUE_APP_WPAPI_URL+"wp/v2/sponsors").then((response) => {
      (this.sponsor = response.data);
    });
  },
}
</script>

<style scoped>
.sponLogo {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  margin-bottom: 40px;
}
.spon {
  padding: 20px;
}
@media only screen and (max-width: 425px) {
  .sponLogo {
    margin-left: auto;
    margin-right: auto;
    max-width: 80px;
    margin-bottom: 40px;
  }
  /* ul.navbar-nav {
    display: contents;
  } */
  ul.navbar-nav.ml-auto {
    display: none;
  }
  ::v-deep ul.navbar-nav {
    display: block;
    float: left;
    padding-left: 0px;
    margin-left: -50px;
  }
  ::v-deep nav.navbar.footNav.navbar-dark.navbar-expand {
    float: left;
  }
}
</style>