<template>
  <div class="contentOuterBox">
    <div class="contentInnerBox">
      <div class="artical">
        <br />
        <b-row>
          <b-col>
            <h1 class="title">
              <span v-if="euAsia === '321'">FALDO SERIES EUROPE</span
              ><span v-if="euAsia === '466'"> FALDO SERIES ASIA </span>
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-img
              v-if="euAsia === '321'"
              :src="config.VUE_APP_WP_DIGITAL_OCEAN + '2020/01/T3B6049.jpg'"
            ></b-img>
            <b-img
              v-if="euAsia === '466'"
              :src="config.VUE_APP_WP_DIGITAL_OCEAN + '2019/05/faldoseriesasia.jpeg'"
            ></b-img>
          </b-col>
          <b-col lg="8">
            <span class="article" v-if="euAsia === '321'">
              <strong
                >Successful non-profit organisation. Developing tomorrow’s
                champions.</strong
              >
              <br />
              <br />
              Established in 1996, The Faldo Series is recognised as the only
              global amateur series for boys and girls. Competitors from ages 12
              to 21 have the opportunity to play WAGR counting events whilst
              testing themselves against the very best juniors around the world,
              with the chance to meet Series Founder, Sir Nick Faldo, at the
              <strong>Faldo Series Europe Grand Final</strong>. <br />

              The current Grand Final venue in Europe is Al Ain Equestrian,
              Shooting & Golf Club, UAE. The Europe schedule now sees 19 events,
              played across 8 countries in three continents.<br />

              Check out the Europe schedule below and sign up for a Qualifying
              event near you…<br /><br />

              <!-- <span
                style="font-weight: bold; font-style: italic; color: #860038;"
                >“ With golf on hold in the UK and other regions due to
                lockdowns, there will be a small delay in announcing the 25th
                Faldo Series Europe schedule. Do stay tuned over the coming
                weeks on our social media channels for further updates. We are
                certainly looking forward to unveiling the full schedule for
                Europe events in due course... ”</span
              > -->
            </span>
            <span class="article" v-if="euAsia === '466'">
              <strong>Growing golf globally. Rapidly expanding. </strong>
              <br />
              <br />
              The Faldo Series expanded into Asia in 2006, recognising the huge
              potential on the Asian junior golf circuit. Helping to reach more
              juniors around the world, by introducing them to the game through
              grassroots initiatives, is at the heart of the Faldo Series’
              expansion to other corners of the globe. <br />

              Like Europe, boys and girls aged 12 to 21 compete for WAGR points,
              aiming to meet Sir Nick at the
              <strong>Faldo Series Asia Grand Final.</strong> The current Grand
              Final venue in Asia is Laguna Golf Lang Co, Vietnam, a Faldo
              Design course.<br />

              Check out the Asia schedule below and sign up for a Qualifying
              event near you…
            </span>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "topSec",
  props: ["euAsia", 'config'],
};
</script>
<style scoped>
.contentOuterBox {
  padding: 20px;
}
.contentInnerBox {
  background-color: white;
  border: 1px solid #e1e1e1;
}
.artical {
  color: #3d3f40;
  font-weight: normal;
  font-size: 17px;
  line-height: 32px;
  padding: 20px 20px !important;
  margin-left: 10px;
}
img {
  width: 100%;
}
</style>
