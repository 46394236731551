<template>
  <div>
    <div>
      <a :href="
          'https://www.instagram.com/' + config.VUE_APP_INSTA_NAME + '/?hl=en'
          " target="_blank" rel="noopener">
      <h2>
        <font-awesome-icon
          id="insta"
          class="icon fa-gradient"
          :icon="['fab', 'instagram']"
        /> Faldo Series
      </h2>
      </a>
    </div>
    <div class="overScroll">
    <b-row>
      <b-col
        lg="12"
        md="12"
        v-for="(insta, index) in insta.data"
        :key="index"
        
      >
        <a :href="insta.permalink" target="_blank" rel="noopener">
          
            <b-img
              v-if="
                insta.media_type === 'CAROUSEL_ALBUM' ||
                  insta.media_type === 'IMAGE'
              "
              class="instaPic"
              :src="insta.media_url"
            ></b-img>
            <b-img v-else class="instaPic" :src="insta.thumbnail_url"></b-img>
          
        </a>
      </b-col>
    </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "instafeed",
  props:['config'],
  data() {
    return {
      insta: [],
    };
  },
  mounted() {
    const api = process.env.VUE_APP_INSTAGRAM_URL + process.env.VUE_APP_INSTAGRAM_KEY;
    axios //add API Call
      .get(api) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.insta = response.data;
      });
  },
};
</script>

<style scoped>
.instaPic {
  width: 100%;
  padding: 20px 0;
}
.overScroll{
  width: 100%;
  height: 960px;
  overflow: scroll;
}
h2{
  font-size: 24px;
    line-height: 1.3;
    color: #404040 !important;
}
#insta{
  font-size: 34px;
}
@media only screen and (max-width: 2560px) {
.outer{
  height: 250px;
}
  .instaHeight.col-lg-4 {
    height: 250px;
  }
}
@media only screen and (max-width: 1440px) {
  .outer {
    height: 185px;
  }
  .instaHeight.col-lg-4 {
    height: 185px;
  }
}
@media only screen and (max-width: 1024px) {
  .outer {
    height: 150px;
  }
  .instaHeight.col-lg-4 {
    height: 150px;
  }
}
@media only screen and (max-width: 768px) {
  .outer {
    height: auto;
  }
}
@media only screen and (max-width: 480px) {
  .outer {
    height: auto;
  }
  .instaHeight.col-lg-4{
    height: auto;
  }
}
</style>

