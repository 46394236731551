import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/reports",
    name: "reports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/reports.vue"),
    props: true
  },
  {
    path: "/tournament/:course_code/:id",
    name: "tournament",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tournament.vue"),
    props: true
  },

];

const router = new VueRouter({
  routes
});

export default router;
