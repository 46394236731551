<template>
  <div class="home">
    <div id="page">
      <div v-if="log.status !== 'OK'">
        <TopSec :euAsia="euAsia" :config="config"/>  
      </div>
      <div>
        <Events
          :euAsia="euAsia"
          :data="tours.tournaments.tournaments_entry"
          :ct="ct"
          :id='season'
          :log='log'
        />
      </div>
      <div>
        <b-row>
          <b-col lg="6">
            <div class="contentOuterBox">
              <div class="contentInnerBox">
                <a class="twitter-timeline" data-height="1000" :href="'https://twitter.com/' + config.VUE_APP_TWITTER_NAME + '?ref_src=twsrc%5Etfw'">Tweets by FaldoSeries</a> <script type="application/javascript" async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="contentOuterBox">
              <div class="contentInnerBox">
                <InstaFeed :config="config"/>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import TopSec from "@/components/topSec.vue";
import Events from "@/components/events.vue";
import InstaFeed from '@/components/instaFeed.vue'
export default {
  name: "Home",
  props:['euAsia', 'tours', 'season', 'ct', 'config', 'log'],
  components: { TopSec, Events, InstaFeed },
};
</script>

<style scoped>
.contentOuterBox {
  padding: 20px;
}
.contentInnerBox {
  background-color: white;
  border: 1px solid #e1e1e1;
  padding: 20px;
}
</style>
