<template>
  <div>
    <b-row>
      <template v-if="log.status !== 'OK'">
        <b-col lg="3" v-if="euAsia === '321'">
          <div class="contentOuterBox">
            <div class="contentInnerBox">
              <b-row>
                <b-col style="width: 220px">
                  <b-button class="greenButton">
                    UK & RSA ENTRIES REGISTER HERE</b-button
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span>
                    OR
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="formTitle">
                    <span>LOGIN</span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form @submit.prevent="onSubmit">
                    <b-form-group
                      id="fieldset-horizontal"
                      label-cols-sm="12"
                      label-cols-lg="12"
                      content-cols-sm
                      content-cols-lg="12"
                      label="Player ID:"
                      label-for="input-horizontal"
                    >
                      <b-form-input
                        id="input-horizontal"
                        v-model="form.playerID"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      type="password"
                      id="fieldset-horizontal-1"
                      label-cols-sm="12"
                      label-cols-lg="12"
                      content-cols-sm
                      content-cols-lg="12"
                      label="Password:"
                      label-for="input-horizontal"
                    >
                      <b-form-input
                        type="password"
                        v-model="form.password"
                        id="input-horizontal-1"
                      ></b-form-input>
                    </b-form-group>
                    <b-button type="submit" class="lBtn">Login</b-button>
                  </b-form>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col>
                  <a href="#">Password Recovery</a>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </template>
      <b-col>
        <div class="contentOuterBox">
          <div class="contentInnerBox">
            <b-tabs>
              <b-tab title="Past" v-bind:class="{ active:  pastEventFilter.length > 0}" class="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>
                        Date
                      </th>
                      <th>
                        Tournament
                      </th>
                      <th>
                        Venue
                      </th>
                      <th class="hidemob">
                        Entries Close
                      </th>
                      <th class="hidemob">
                        Reports
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(tours, index) in pastEventFilter" :key="index">
                      <td>
                        {{ tours.start_date }}
                      </td>
                      <td>
                        <b-nav-item
                          :to="'/tournament/' + tours.code + '/' + id"
                        >
                          {{ tours.short_name }}
                        </b-nav-item>
                      </td>
                      <td>
                        {{ tours.course }}
                      </td>
                      <td class="hidemob">{{ tours.end_date }} - {{ tours.closing_time }}</td>
                      <td class="hidemob">
                        <b-nav card-header pills>
                          <b-dropdown
                            id="dropdown-dropleft"
                            dropleft
                            v-if="Array.isArray(tours.reports.reports_entry)"
                            text="Select Report..."
                          >
                            <b-dropdown-item
                              :to="{
                                name: 'reports',
                                query: {
                                  url: reports.report_url,
                                  id: id,
                                  code: tours.code,
                                  title: reports.report_title,
                                },
                              }"
                              v-for="(reports, index) in tours.reports
                                .reports_entry"
                              :key="index"
                              :title="reports.report_title"
                              >{{ reports.report_title }}</b-dropdown-item
                            >
                          </b-dropdown>
                          <b-dropdown
                            id="dropdown-dropleft"
                            dropleft
                            v-else
                            text="Select Report..."
                          >
                            <b-dropdown-item
                              :to="{
                                name: 'reports',
                                query: {
                                  url: reports.report_url,
                                  id: id,
                                  code: tours.code,
                                  title: reports.report_title,
                                },
                              }"
                              v-for="(reports, index) in tours.reports"
                              :key="index"
                              title="reports.report_title"
                              >{{ reports.report_title }}</b-dropdown-item
                            >
                          </b-dropdown>
                        </b-nav>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-tab>
              <b-tab title="Future" v-bind:class="{ active:  FutureEventFilter.length > 0}" class="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>
                        Date
                      </th>
                      <th>
                        Tournament
                      </th>
                      <th>
                        Venue
                      </th>
                      <template v-if="log.status === 'OK'">
                        <th class="hidemob">
                          Entries Open
                        </th>
                      </template>
                      <th class="hidemob">
                        Entries Close
                      </th>
                      <template v-if="log.status === 'OK'">
                        <th class="hidemob">
                          Entry Status
                        </th>
                        <th class="hidemob">
                          Entry Fee
                        </th>
                      </template>
                      <th class="hidemob">
                        Reports
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(tours, index) in FutureEventFilter"
                      :key="index"
                      :class="{ current: tours.code === ct }"
                    >
                      <td>
                        {{ tours.start_date }}
                      </td>
                      <td>
                        <b-nav-item
                          :to="'/tournament/' + tours.code + '/' + id"
                        >
                          {{ tours.full_name }}
                        </b-nav-item>
                      </td>
                      <td>
                        {{ tours.course }}
                      </td>
                      <template v-if="log.status === 'OK'">
                        <td>
                          <span v-if="tours.entry_open === 'Y'"
                            >Entries Open</span
                          ><span v-else> N/A </span>
                        </td>
                      </template>
                      <td class="hidemob">{{ tours.end_date }} - {{ tours.closing_time }}</td>
                      <template v-if="log.status === 'OK'">
                        <td class="hidemob">
                          <span
                            v-if="tours.entry_open === 'Y'"
                            :class="{
                              'entered-text-detail':
                                tours.entry_status == 'E' ||
                                tours.entry_status == 'Q',
                            }"
                            >Entered</span
                          ><span
                            :class="{
                              'not-entered-text-detail':
                                tours.entry_status == 'W',
                              'no-entered-rec-detail':
                                tours.entry_status == 'X' ||
                                tours.entry_status == 'P',
                            }"
                            v-else
                          >
                            Not Entered
                          </span>
                        </td>
                        <td class="hidemob">
                          <span v-if="tours.entry_open === 'Y'"
                            >Entries Open</span
                          ><span v-else> N/A </span>
                        </td>
                      </template>
                      <td class="hideMob">
                        <b-nav card-header pills>
                          <b-dropdown
                            id="dropdown-dropleft"
                            dropleft
                            v-if="Array.isArray(tours.reports.reports_entry)"
                            text="Select Report..."
                          >
                            <b-dropdown-item
                              :to="{
                                name: 'reports',
                                query: {
                                  url: reports.report_url,
                                  id: id,
                                  code: tours.code,
                                  title: reports.report_title,
                                },
                              }"
                              v-for="(reports, index) in tours.reports
                                .reports_entry"
                              :key="index"
                              :title="reports.report_title"
                              >{{ reports.report_title }}</b-dropdown-item
                            >
                          </b-dropdown>
                          <b-dropdown
                            id="dropdown-dropleft"
                            dropleft
                            v-else
                            text="Select Report..."
                          >
                            <b-dropdown-item
                              :to="{
                                name: 'reports',
                                query: {
                                  url: reports.report_url,
                                  id: id,
                                  code: tours.code,
                                  title: reports.report_title,
                                },
                              }"
                              v-for="(reports, index) in tours.reports"
                              :key="index"
                              title="reports.report_title"
                              >{{ reports.report_title }}</b-dropdown-item
                            >
                          </b-dropdown>
                        </b-nav>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import socketServe from "@/js/socketio-services.js";
import { bus } from "../main.js";
export default {
  name: "events",
  props: ["euAsia", "data", "ct", "id", "log"],
  data() {
    return {
      current: "current",
      toursEU: [],
      toursAsia: [],
      season: "",
      form: {
        playerID: "",
        password: "",
      },
      fields: [
        { key: "start_date", label: "Dates" },
        { key: "full_name", label: "Tournament" },
        { key: "course", label: "Venue" },
        { key: "end_date", label: "Entries Close" },
        { key: "reports", label: "Reports" },
      ],
    };
  },
  methods: {
    onSubmit: function(playerID, password) {
      playerID = this.form.playerID;
      password = this.form.password;
      socketServe.openSocket(playerID, password);
      this.loggedIn();
    },
    loggedIn() {
      setTimeout(() => {
        const playerName = sessionStorage.getItem("playerName", "");
        const playerID = sessionStorage.getItem("playerID", "");
        const status = sessionStorage.getItem("status", "");
        this.log = {
          playerName: playerName,
          playerID: playerID,
          status: status,
        };
        bus.$emit("log", { log: this.log });
      }, 5000);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    signOut() {
      sessionStorage.playerName = "";
      sessionStorage.playerID = "";
      sessionStorage.password = "";
      sessionStorage.status = "";
      this.log = "";
    },
  },
  computed: {
    pastEventFilter: function() {
      return this.data.filter((data) => data.days_away < 0);
    },
    FutureEventFilter: function() {
      return this.data.filter((data) => data.days_away >= 0);
    },
  },
  mounted() {
    this.loggedIn();
  },
};
</script>

<style scoped>
tr:nth-child(odd) {
  background: #dfe1e6;
}
.contentOuterBox {
  padding: 20px;
}
.contentInnerBox {
  background-color: white;
  border: 1px solid #e1e1e1;
  padding: 20px;
}
.greenButton {
  width: 100%;
  float: left;
  background: url(../img/green_btn.png) no-repeat left top;
  background-size: 100% 100%;
  height: 71px;
  color: #fff;
  color: #ffffff;
  text-transform: uppercase;
  font-family: arial;
  text-shadow: 1px 1px 1px 1px #006c0f;
  border: none;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  -webkit-transition: all 400ms linear;
  -moz-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  -ms-transition: all 400ms linear;
  transition: all 400ms linear;
  padding: 0 0 11px;
}
.formTitle {
  width: auto;

  color: #2e4c73;
  font-size: 16px;

  text-shadow: 1px 1px 1px #fff;
  text-transform: uppercase;
  padding: 13px 0 11px 0;
}
.lBtn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
a {
  color: #860038 !important;
}
::v-deep .nav-tabs > .nav-item {
  width: 50%;
}
::v-deep .nav-tabs > li > a {
  color: #004812;
  background-color: #ffffff;
  border-radius: 0px;
  position: relative;
  display: block;
  padding: 10px 15px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
}
::v-deep .nav-tabs > li > .nav-link.active {
  color: #ffffff !important;
  cursor: default;
  background-color: #004812;
  border: 1px solid #004812;
  border-bottom-color: transparent;
}
li {
  list-style: none;
}

.tab-pane {
  margin-top: -1px;
}
::v-deep th {
  background-color: #004812 !important;
  padding: 13px;
  color: #ffffff;
  font-size: 17px;
  line-height: 31px;
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  font-weight: initial;
}

::v-deep td {
  font-size: 14px !important;
  line-height: 22px;
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  vertical-align: middle;
  padding-left: 8px !important;
}
::v-deep td > li > a {
  font-size: 14px !important;
  line-height: 22px !important;
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  vertical-align: middle;
  padding-left: 8px !important;
}
table {
  width: 100%;
}
.current {
  background-color: #860038 !important;
  color: white;
}
.current > td > li > a {
  color: white !important;
}
::v-deep .dropdown-toggle {
  width: 142px;
  background-color: white;
  color: black;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 2px;
}
.no-entered-rec-detail {
  border-color: #e6b500;
  background-color: #ffc900;
  color: #fff !important;
  padding-top: 2px !important;
  padding-right: 5px !important;
  padding-bottom: 2px !important;
  padding-left: 5px !important;
  border-radius: 4px !important;
  float: none !important;
  display: inline-block;
}
.entered-text-detail {
  border-color: #28a54c;
  background-color: #33cd5f;
  color: #fff !important;
  padding-top: 2px !important;
  padding-right: 5px !important;
  padding-bottom: 2px !important;
  padding-left: 5px !important;
  border-radius: 4px !important;
  float: none !important;
  display: inline-block;
}

@media only screen and (max-width: 425px) {
  th.hidemob {
    display: none;
  }
  td.hidemob {
    display: none;
  }
}
</style>
